import {Expose} from 'class-transformer';
import {Moment} from 'moment';

import {BoatShortInfoCharterListDto} from './boat-charter.model';

import {CompanyDto} from '../auth';
import {BoatSubscriptionTrialType} from '../boat-subscription';
import {BankAccountStatementsItem, CreditCardStatementsItem} from '../credit-cards/credit-cards';
import {
  ActivityTypeDto,
  BoatTypeDto,
  BrandDto,
  CityDto,
  ClassNameDto,
  CountryDto,
  CurrencyDto,
  TimezoneItemDto,
} from '../directories';
import {CreditCardDto} from '../directories/credit-card.model';
import {
  ExpenseCategoryDto,
  ExpenseCrewDto,
  ExpenseDepartmentDto,
  filterCrewListByRoles,
  SubCategoryDto,
} from '../expense';
import {FileListItemI} from '../file';
import {AisLocationDto, BoatLocationDto, LocationDto, LocationDtoI, LocationPlaceDto} from '../location';
import {UploadedFile} from '../uploaded-file';

export interface BoatRequiredInfoI {
  name: string;
  boatTypeId: number;
  boatActivityTypeId: number;
  boatLength: number;
  maxCrewOnBoat: number;
  builtYear: number;
  flagId: number;
  officialNumber: string;
  cabins: number | null;
  mmsi: string | number | null;
  imoNumber: string | number | null;
  timezone: string;
  logo?: UploadedFile | string | null;
  image?: UploadedFile | string | null;
}

export class AddBoatDto implements BoatRequiredInfoI {
  name: string;
  boatTypeId: number;
  boatActivityTypeId: number;
  boatLength: number;
  maxCrewOnBoat: number;
  builtYear: number;
  flagId: number;
  officialNumber: string;
  cabins: number | null;
  mmsi: string | number | null;
  imoNumber: string | number | null;
  timezone: string;
  logo?: UploadedFile | string | null;
  image?: UploadedFile | string | null;
  ais?: number;
  tendersAndSeaToys: string | null;

  constructor(payload: AddBoatFormI) {
    this.name = payload.name;
    this.boatTypeId = payload.boatTypeId;
    this.boatActivityTypeId = payload.boatActivityTypeId;
    this.boatLength = payload.boatLength;
    this.maxCrewOnBoat = payload.maxCrewOnBoat;
    this.builtYear = payload.builtYear;
    this.flagId = payload.flagId;
    this.officialNumber = payload.officialNumber;
    this.cabins = payload.cabins;
    this.mmsi = payload.mmsi;
    this.imoNumber = payload.imoNumber;
    this.logo = payload.logo instanceof UploadedFile ? payload.logo.fileName : payload.logo;
    this.image = payload.image instanceof UploadedFile ? payload.image.fileName : payload.image;
    this.ais = payload.ais || 0;
    this.timezone = payload.timezone;
    this.tendersAndSeaToys = payload.tendersAndSeaToys;
  }
}

export interface AddBoatResponseDtoI {
  id: number;
}

export type BoatGeneralInfo = Omit<BoatRequiredInfoI, 'officialNumber' | 'mmsi' | 'imoNumber'> & BoatAisStatus & {
  boatBrandId: number;
  boatBrandOther: string | null;
  boatModel: string;
  location: LocationPlaceDto;
  boatWidth: number;
  boatDraft: number;
  boatTonnage: number;
  maxGuest: number;
  tendersAndSeaToys: string | null;
};

export type BoatRegistrationInfo = Pick<BoatRequiredInfoI, 'officialNumber'> & BoatAisStatus & {
  portOfRegistry: string;
  radioCallSign: string;
  imoNumber: string;
  mmsi: string;
  hullNumber: string;
  boatClassNameId: number;
  boatClassNameOther: string | null;
};

export interface BoatCompanyLocation {
  location: LocationDtoI;
  address: string;
  zipCode: string;
}

export interface BoatCompany {
  companyName: string;
  companyOfficialNumber: string;
  companyLocation: BoatCompanyLocation;
}

export interface BoatDto {
  general: BoatGeneralInfo;
  company: BoatCompany;
  registration: BoatRegistrationInfo;
}

interface BoatUpdateGeneralDtoI extends Omit<BoatGeneralInfo, 'location'> {
  logoRemove?: 1;
  imageRemove?: 1;
}

export interface BoatGeneralFormI extends Omit<BoatRequiredInfoI, 'officialNumber'> {
  boatBrandId: number;
  boatBrandOther: string | null;
  boatModel: string;
  location: LocationDto;
  boatWidth: number;
  boatDraft: number;
  boatTonnage: number;
  maxGuest: number;
  tendersAndSeaToys: string | null;
}

export class BoatUpdateGeneralDto implements Omit<BoatUpdateGeneralDtoI, 'aisEnabled'> {
  name: string;
  flagId: number;
  boatTypeId: number;
  boatActivityTypeId: number;
  boatBrandId: number;
  boatBrandOther: string | null;
  boatModel: string;
  builtYear: number;
  boatLocationLat: number | null;
  boatLocationLng: number | null;
  boatLocationName: string | null;
  boatLength: number;
  boatWidth: number;
  boatDraft: number;
  boatTonnage: number;
  maxCrewOnBoat: number;
  maxGuest: number;
  cabins: number | null;
  timezone: string;
  image?: UploadedFile | string | null;
  logo?: UploadedFile | string | null;
  logoRemove?: 1;
  imageRemove?: 1;
  tendersAndSeaToys: string | null;

  constructor(payload: BoatGeneralFormI) {
    this.name = payload.name;
    this.flagId = payload.flagId;
    this.boatTypeId = payload.boatTypeId;
    this.boatActivityTypeId = payload.boatActivityTypeId;
    this.boatBrandId = payload.boatBrandId;
    this.boatBrandOther = payload.boatBrandOther;
    this.boatModel = payload.boatModel;
    this.builtYear = payload.builtYear;
    const {location} = payload;
    if (location) {
      this.boatLocationName = location.address;
      this.boatLocationLat = location.lat;
      this.boatLocationLng = location.lng;
    }
    this.boatLength = payload.boatLength;
    this.boatWidth = payload.boatWidth;
    this.boatDraft = payload.boatDraft;
    this.boatTonnage = payload.boatTonnage;
    this.maxCrewOnBoat = payload.maxCrewOnBoat;
    this.maxGuest = payload.maxGuest;
    this.cabins = payload.cabins;
    this.timezone = payload.timezone;
    this.tendersAndSeaToys = payload.tendersAndSeaToys;

    const {image, logo} = payload;
    if (image === null) {
      this.imageRemove = 1;
    }
    if (logo === null) {
      this.logoRemove = 1;
    }
    if (typeof image !== 'string') {
      this.image = image ? image.fileName : '';
    }
    if (typeof logo !== 'string') {
      this.logo = logo ? logo.fileName : '';
    }
  }
}

export class BoatRegistrationUpdateDto {
  portOfRegistry: string;
  officialNumber: string;
  radioCallSign: string;
  imoNumber: string;
  mmsi: string;
  hullNumber: string;
  boatClassNameId: number;
  boatClassNameOther: string | null;

  constructor(payload: BoatRegistrationFormsI) {
    this.portOfRegistry = payload.portOfRegistry;
    this.officialNumber = payload.officialNumber;
    this.radioCallSign = payload.radioCallSign;
    this.imoNumber = payload.imoNumber;
    this.mmsi = payload.mmsi;
    this.hullNumber = payload.hullNumber;
    this.boatClassNameId = payload.boatClassNameId;
    this.boatClassNameOther = payload.boatClassNameOther;
  }
}

export class BoatCompanyUpdateDto {
  companyName: string;
  owningCompanyOfficialNumber: string;
  companyLocationCountryId: number | null;
  companyLocationCityId: number | null;
  companyLocationAddress: string | null;
  companyLocationZipCode: string | null;

  constructor(payload: BoatCompanyFormI) {
    this.companyName = payload.companyName;
    this.owningCompanyOfficialNumber = payload.companyOfficialNumber;
    const {companyLocation} = payload;
    this.companyLocationCountryId = companyLocation.location.countryId;
    this.companyLocationCityId = companyLocation.location.cityId;
    this.companyLocationAddress = companyLocation.address;
    this.companyLocationZipCode = companyLocation.zipCode;
  }

}

export type BoatUpdatePayload = BoatUpdateGeneralDto | BoatCompanyUpdateDto | BoatRegistrationUpdateDto;
export type BoatUpdateMode = keyof BoatDto;

export interface BoatUpdateParams extends BoatPayload {
  mode: BoatUpdateMode;
}

export interface BoatFlagDto {
  icon: string;
  country: string;
}

export interface BoatInfoImagesI {
  image: string | null;
  logo: string | null;
}

export interface BoatInfoNameI {
  name: string;
  boatType: string;
}

export interface BoatCrew {
  id: number;
  name: string;
  image: number | null;
}

export interface BoatInfoDtoI extends BoatInfoImagesI, BoatInfoNameI {
  boatType: string;
  boatBrand: string;
  boatModel: string;
  builtYear: number;
  boatActivityType: string;
  boatLength: number;
  boatWidth: number | null;
  boatDraft: number;
  boatTonnage: number;
  location: AisLocationDto | null;
  flag: BoatFlagDto;
  maxCrew: number | null;
  maxGuest: number | null;
  myCrew: BoatCrew[];
  cabins: number;
}

export interface BoatRegistrationDetailsDtoI {
  portOfRegistry: string;
  officialNumber: string;
  radioCallSign: string;
  imoNumber: number;
  mmsi: number;
  hullNumber: string;
  boatClassName: string;
}

export interface BoatCompanyInfoDtoI {
  address: string;
  name: string;
  officialNumber: string;
}

export interface BoatGeneralInfoDtoI {
  general: BoatInfoDtoI;
  registration: BoatRegistrationDetailsDtoI;
  company: BoatCompanyInfoDtoI;
}

export type BoatExpenseDto = Readonly<{
  total: number;
  lastUploading: string;
  yearsReportMonth: number[];
}>;

export type PettyCashBalance = Readonly<{
  date: string;
  total: number;
}>;

export type CreditCardStatementsDto = Readonly<{
  dateUpdate: string;
  disableCreditCards: boolean;
  list: CreditCardStatementsItem[];
}>;

export type BankAccountStatements = Readonly<{
  dateUpdate: string;
  disableBankAccounts: boolean;
  list: BankAccountStatementsItem[];
}>;

export type PettyCashBalanceByCurrencies = Readonly<{
  id: number;
  name: string;
  badge: string;
  value: number;
}>;

export class BoatShortInfoDto {
  @Expose()
  get subCategoryList(): SubCategoryDto[] {
    const categories = this.category;
    if (!categories || !categories.length) {
      return [];
    }
    return categories.map(category => category.subcategories || []).reduce((acc, val) => [...acc, ...val], []);
  }

  @Expose()
  get crewList(): ExpenseCrewDto[] {
    return filterCrewListByRoles(this.myCrew, true, ['role_boat_manager', 'role_boat_owner']);
  }

  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly shortName: string,
    public readonly boatLength: number,
    public readonly image: string,
    public readonly flag: string,
    public readonly myCrew: ExpenseCrewDto[],
    public readonly expenses: BoatExpenseDto,
    public readonly pettyCashBalance: PettyCashBalance,
    public readonly pettyCashBalanceByCurrencies: PettyCashBalanceByCurrencies[],
    public readonly department: ExpenseDepartmentDto[],
    public readonly category: ExpenseCategoryDto[],
    public readonly isBoatAdmin: boolean,
    public readonly currency: CurrencyDto[],
    public readonly creditCard: CreditCardDto[],
    public readonly creditCardStatements: CreditCardStatementsDto | null,
    public readonly bankAccountStatements: BankAccountStatements | null,
    public readonly boatDateUpdate: number,
    public readonly subscription: boolean,
    public readonly supplierEnabled: boolean,
    public readonly expenseCount: number,
    public readonly trialType: BoatSubscriptionTrialType,
    public readonly company: CompanyDto | null,
    public readonly charterList: BoatShortInfoCharterListDto,
    public readonly location: BoatLocationDto | null,
    public readonly boatActivityType: BoatActivityType,
    public readonly enabledCharterPettyCashBalance: boolean,
    public readonly defaultBoatTaskAssigneeCreator: boolean,
    public readonly timezone: BoatTimezoneDto,
    public readonly timezoneSelected: boolean,
    public readonly numberOfCabinsIndicated: boolean,
    public readonly generalUpdate: string | null,
    public readonly charterPreferenceListEnabled: boolean,
    public readonly boatType: number,
    public readonly logo: string,
  ) {
  }
}

export enum BoatActivityType {
  Private = 1,
  Charter
}

export interface BoatPayload {
  boatId: number;
}

export interface BoatPayloadDto {
  readonly boat: number;
}

export enum OutstandingStatusType {
  Payment = 1,
  WorkOrder
}

export class ChangeOutstandingStatus {
  constructor(
    public readonly outstandingPaymentId: number,
    public readonly type: OutstandingStatusType,
    public readonly id: number,
  ) {
  }
}

export class ChangeOutstandingStatusPayload extends ChangeOutstandingStatus {
  constructor(
    public readonly boatId: number,
    changeStatus: ChangeOutstandingStatus,
  ) {
    super(
      changeStatus.outstandingPaymentId,
      changeStatus.type,
      changeStatus.id,
    );
  }
}

export interface BoatParamsPayload extends BoatPayload {
  year: number | null;
  month: number | null;
  customPeriod: { from: string, to: string } | null;
}

export class EventDateTimePeriod {
  constructor(
    public readonly dateTimeStart: Moment,
    public readonly dateTimeEnd: Moment,
  ) {
  }
}

export class BoatTaskDefaultAssigneePayload {
  constructor(
    public readonly boat: number,
    public readonly crew: number | null,
  ) {
  }
}

export interface BoatAisNotifyDto {
  readonly showNotification: boolean;
  readonly mmsi: number | string | null;
  readonly imoNumber: number | string | null;
}

export type BoatTimezoneDto = Omit<TimezoneItemDto, 'id'>;

export interface BoatSupplierBankDetailsFormDto {
  readonly id: number;
  readonly beneficiaryName: string;
  readonly bankName: string;
  readonly bankAccount: string;
  readonly bankAccountNotRelevant: boolean;
  readonly iban: string;
  readonly ibanNotRelevant: boolean;
  readonly swift: string;
  readonly bankAddress: string;
  readonly beneficiaryAddress: string;
  readonly bankDetailFiles: FileListItemI[] | null;
}

export interface BoatSupplierDetailsDto {
  readonly id: number;
  readonly supplierName: string;
  readonly bankDetails: Omit<BoatSupplierBankDetailsFormDto, 'bankDetailFiles'> & { files: FileListItemI[] | null };
}

export type BoatRegistrationFormsI = BoatRegistrationInfo;

export type BoatCompanyFormI = BoatCompany;

export type AddBoatFormI = BoatRequiredInfoI & {
  ais: number
  readonly tendersAndSeaToys: string | null,
};

export class AddBoatDirectories {
  constructor(
    public readonly boatTypes: BoatTypeDto[],
    public readonly activityTypes: ActivityTypeDto[],
    public readonly years: number[],
    public readonly countries: CountryDto[],
    public readonly timezones: TimezoneItemDto[],
  ) {
  }
}

export class BoatGeneralDirectories extends AddBoatDirectories {
  constructor(
    public readonly boatTypes: BoatTypeDto[],
    public readonly activityTypes: ActivityTypeDto[],
    public readonly boatBrands: BrandDto[],
    public readonly years: number[],
    public readonly countries: CountryDto[],
    public readonly timezones: TimezoneItemDto[],
  ) {
    super(boatTypes, activityTypes, years, countries, timezones);
  }
}

export class BoatRegistrationDirectories {
  constructor(
    public readonly className: ClassNameDto[],
  ) {
  }
}

export class BoatCompanyDirectories {
  constructor(
    public readonly countries: CountryDto[],
    public readonly cities: CityDto[],
  ) {
  }
}

export type BoatAisStatus = {
  readonly aisEnabled: boolean;
};
