import {TableDataDto} from './dashboard-table-data.model';

import {ReportViewType} from '../financial-report';

export class MonthlyExpenseDto {
  constructor(
    public readonly data: TableDataDto,
    public readonly currentDate: string | Date = new Date(),
  ) {
  }
}

export enum AppChartType {
  PAYMENT_METHODS = 1,
  BY_CATEGORY = 2,
  BY_SUBCATEGORY = 3,
  BY_SUBCATEGORY_YTD = 4,
  BY_CATEGORY_VS_PREVIOUS_YEAR = 6,
  BY_DEPARTMENT = 7,
  ALL = 8
}

export class ReportChartParams {
  constructor(
    public readonly chartType: AppChartType,
    public readonly viewType: ReportViewType,
    public readonly id: number | null = null,
    public readonly year: number | null = null,
    public readonly month: number | null = null,
    public readonly currencyId: number | null = null,
    public readonly dateFrom: string | null = null,
    public readonly dateTo: string | null = null,
  ) {
  }
}

export class ChartInfoPayload {
  constructor(
    public readonly id: number,
    public readonly currencyId: number | null = null,
  ) {
  }
}
