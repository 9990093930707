import {Expose, plainToInstance, Type} from 'class-transformer';

import {ChartData} from '../../modules/chart';
import {BoatPayload} from '../boat';
import {TableDataDto} from '../dashboard';
import {FinancialData} from '../financial-data';
import {LabeledItem, Month} from '../general';

export class ReportFilterDto {
  constructor(
    public readonly boatId: number,
    public readonly year: number | null = null,
    public readonly month: number | null,
    public typeChart: 'years' | null = null,
    public readonly customPeriod: { from: string, to: string } | null = null,
  ) {
  }
}

export type BudgetPreview = Readonly<{
  paymentMethod: string;
  dateReceipt: string;
  category: string;
  description: string;
  amount: string;
}>;

export type CardExpenses = Readonly<{
  actions: string;
  currency: string;
}>;

export type CashReport = Readonly<{
  cardExpenses: CardExpenses[];
  budget: BudgetPreview[];
}>;

export type ExistReportDto = Readonly<{
  years: number[];
  budgets: ExistBudgetReportDto[];
}>;

export type ExistBudgetReportDto = Readonly<{
  id: number;
  name: string;
  year: number;
}>;

export type FinancialReportFormDirectories = Readonly<{
  months: Month[];
  years: number[];
  currentMonthDate: string,
  budgets: ExistBudgetReportDto[];
  reportTypes: LabeledItem<ComparisonReportType>[];
}>;

export class MonthlyReportDto {
  @Type(() => ReportExpenseDto) readonly categories: ReportExpenseDto;
  @Type(() => FinanceSubcategoryDto) readonly financeSubcategories: FinanceSubcategoriesDto;
  @Type(() => ReportPaymentDetailsDto) readonly paymentDetails: ReportPaymentDetailsDto;
  @Type(() => ReportExpenseDto) readonly paymentMethods: ReportExpenseDto;
  @Type(() => ReportExpenseDto) readonly subcategories: ReportExpenseDto;
  @Type(() => ReportExpenseDto) readonly totalExpenses: ReportExpenseDto[];
}

export class CustomPeriodReportDto {
  @Type(() => ReportExpenseDto) readonly categories: ReportExpenseDto;
  @Type(() => ExpenseReportDistributionDto) readonly distributionSubcategoriesMonths: ExpenseReportDistributionDto;
  @Type(() => ReportExpenseDto) readonly subcategories: ReportExpenseDto;
  @Type(() => ReportExpenseDto) readonly totalExpenses: ReportExpenseDto[];
  readonly paymentMethods: TableDataDto;
  readonly totalMonthsExpenses: ReportItem<MonthExpenseReportItem>;
  private paymentMethodsChartData: ReportExpenseDto | undefined;

  get paymentMethodsChart() {
    if (!this.paymentMethodsChartData) {
      this.paymentMethodsChartData = plainToInstance(ReportExpenseDto, this.paymentMethods) as any;
    }
    return this.paymentMethodsChartData;
  }
}

export class AnnualReportDto extends CustomPeriodReportDto {
  readonly comparisonYearBudget: ComparisonReportDto;
}

export class ExpenseReportItem {
  @Expose({name: 'name'}) label: string;
  @Expose({name: 'currentExpense'}) value: number;
  readonly id?: number;
  readonly color?: string;
}

export class MonthExpenseReportItem {
  constructor(
    public readonly month: string,
    public readonly currentPercent: number,
    public readonly currentExpense: number,
  ) {
  }
}

export class ReportItem<T> {
  constructor(
    public readonly items: T[],
    public readonly total: number,
  ) {
  }
}

export class ReportExpenseDto {
  @Type(() => ChartData) items: ChartData[];
  readonly total: number;
  readonly color?: string;
  readonly name?: string;
}

export class ExpenseReportDistributionItem {
  @Type(() => ExpenseReportItem) items: ExpenseReportItem[];
  readonly id: number;
  readonly name: string;
  readonly total: number;
}

export class ExpenseReportDistributionDto {
  @Type(() => ExpenseReportDistributionItem) items: ExpenseReportDistributionItem[];
  readonly total: number;
}

export enum ReportViewType {
  Overview = 'overview',
  FullView = 'fullview',
}

export type ReportType = 'monthly' | 'annual' | 'custom';

export class MonthlyFinancialReportDto {
  constructor(
    public readonly boatId: number,
    public readonly year: number,
    public readonly month: number | null,
  ) {
  }
}

export class YearFinancialReportDto {
  constructor(
    public readonly boatId: number,
    public readonly year: number,
    public readonly comparisonYear: number,
    public readonly comparisonType: ComparisonReportType,
    public readonly comparisonValue: number,
  ) {
  }
}

export class CustomPeriodFinancialReportDto {
  constructor(
    public readonly boatId: number,
    public readonly dateStart: string,
    public readonly dateEnd: string,
  ) {
  }
}

export class FinancialReportFileDto {
  constructor(
    public readonly url: string,
    public readonly payload: MonthlyFinancialReportDto | YearFinancialReportDto | CustomPeriodFinancialReportDto,
  ) {
  }
}

export class FinanceSubcategoryDto {
  readonly items: FinancialData[];
  readonly name: string;
  readonly total: number;
}

export class FinanceSubcategoriesDto {
  readonly items: FinanceSubcategoryDto[];
}

export class CashReportItem {
  constructor(
    public topData: CashReportCurrencies,
    public readonly items: FinancialData[],
    public readonly total: number,
  ) {
  }
}

export class CashReportCurrencies {
  constructor(
    public readonly currencies: string[],
    public readonly cashIn: CashReportItemCurrency[] | null = null,
    public readonly cashOut: CashReportItemCurrency[] | null = null,
    public readonly atmWithdrawal: CashReportItemCurrency[] | null = null,
    public readonly cashExpenses: CashReportItemCurrency[] | null = null,
    public readonly currentPCB: CashReportItemCurrency[] | null = null,
    public readonly previousPCB: CashReportItemCurrency[] | null = null,
  ) {
  }
}

export type CashReportItemCurrency = Readonly<{
  badge: string;
  value: number;
  name: string;
}>;

export class ReportCreditCard {
  constructor(
    public readonly name: string,
    public readonly total: string,
    public readonly items: FinancialData[],
    public readonly withdrawals: number,
    public readonly creditCardExpenses: number,
  ) {
  }
}

export class ReportCreditCardItem {
  constructor(
    public readonly items: ReportCreditCard[],
  ) {
  }
}

export class DirectPaymentReportItem {
  constructor(
    public readonly items: FinancialData[],
    public readonly total: number,
  ) {
  }
}

export class WireTransferReportItem {
  constructor(
    public readonly items: FinancialData[],
    public readonly total: number,
  ) {
  }
}

export type ReportBankAccountItem = Readonly<{
  currencies: string[];
  currentBAB: CashReportItemCurrency[] | null;
  income: CashReportItemCurrency[] | null;
  items: FinancialData[];
  name: string;
  outcome: CashReportItemCurrency[] | null;
  previousBAB: CashReportItemCurrency[] | null;
  total: number;
  wireTransfer: CashReportItemCurrency[] | null;
}>

export class ReportPaymentDetailsDto {
  constructor(
    public readonly cash: CashReportItem,
    public readonly creditCard: ReportCreditCardItem,
    public readonly bankAccounts: ReportBankAccountItem[],
    public readonly directPayment: DirectPaymentReportItem,
    public readonly wireTransfer: WireTransferReportItem,
  ) {
  }
}

export interface CustomPeriodReportPayload extends BoatPayload {
  dateStart: string;
  dateEnd: string;
}

export enum ComparisonReportType {
  Year = 1,
  Budget,
}

export interface ComparisonReportPayload extends ComparisonReportFilterFormI, BoatPayload {
  year?: number;
}

export interface ComparisonReportFilterFormI {
  comparisonYear: number;
  comparisonType: ComparisonReportType;
  comparisonValue: number;
}

export type ComparisonSelectedValue = Readonly<{
  comparisonYear: number;
  comparisonType: ComparisonReportType;
  comparisonValue: string;
}>;

export type ComparisonReportDto = Readonly<{
  table: ComparisonReportTableDto;
  chart: ComparisonReportChartDto;
}>;

export type ComparisonReportTableDto = Readonly<{
  total: ComparisonReportTotalDto;
  category: ComparisonReportTableItemDto[];
}>;

export type ComparisonReportTotalDto = Readonly<{
  first: number;
  second: number;
  difference: number;
  percent?: number;
}>;

export type ComparisonReportTableSubItemDto = Readonly<{
  id: number;
  name: string;
  first: number;
  second: number;
  difference: number;
  percent: number;
}>;

export type ComparisonReportTableItemDto = Readonly<{
  id: number;
  name: string;
  subcategory: ComparisonReportTableSubItemDto[];
  total: ComparisonReportTotalDto;
}>;

export type ComparisonReportChartDto = [ComparisonReportChartItemDto, ComparisonReportChartItemDto];

type ComparisonReportChartItemDto = Readonly<{
  year: string;
  monthExpenses: Array<number | null>;
}>;
