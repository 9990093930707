import {PettyCashBalanceByCurrencies} from '../boat';

export class TableDataDto {
  constructor(
    public readonly items: TableCategoryRowItem[],
    public readonly total?: number,
    public readonly previousTotal?: TableCategoryRowItem | number,
    public readonly other?: TableCategoryRowItem[],
  ) {
  }
}

export class TableCategoryRowItem {
  constructor(
    public readonly name: string = '',
    public readonly currentExpense?: number,
    public readonly previousExpense?: number,
    public readonly currentPercent?: number,
    public readonly previousPercent?: number,
    public readonly id?: number,
    public readonly currencies?: PettyCashBalanceByCurrencies[],
  ) {
  }
}
