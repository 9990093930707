import {CheckboxGroupItem} from '@controls/seazone-checkbox-group-deprecated';

import {FinancialDataFormI} from './financial-data-form.model';

import {AppChartType} from '../dashboard';
import {CurrencyDto, PaymentMethodDto} from '../directories';
import {
  ExpenseDepartmentDto,
  ExpenseStatus,
  ExpenseStatusItem,
  ExpenseType,
  ExpenseTypeItem,
  FinancialDataCrewDirectory,
} from '../expense';
import {ReportViewType} from '../financial-report';
import {SortDirection, SortItem} from '../shared';

export class FinancialDataDirectories {
  constructor(
    public readonly categories: CheckboxGroupItem[] = [],
    public readonly crewList: FinancialDataCrewDirectory[] = [],
    public readonly departments: ExpenseDepartmentDto[] = [],
    public readonly paymentMethods: PaymentMethodDto[] = [],
    public readonly currency: CurrencyDto[] = [],
    public readonly expenseStatus: ExpenseStatusItem[] = [],
    public readonly reportYears: CheckboxGroupItem[] = [],
    public readonly expenseTypes: ExpenseTypeItem[] = [],
  ) {
  }
}

export class FinancialDataFilter {
  constructor(
    public filter: FinancialDataFormI = {} as FinancialDataFormI,
    public page: number | null = null,
    public sort: SortItem | null = null,
    public search: string = '',
  ) {
  }
}

export class FinancialDataChoosenFilters {
  constructor(
    public readonly reportMonth: FinancialDataFilterBadge | null,
    public readonly date: FinancialDataFilterBadge | null,
    public readonly crew: FinancialDataFilterBadge | null,
    public readonly department: FinancialDataFilterBadge | null,
    public readonly paymentMethod: FinancialDataFilterBadge | null,
    public readonly categories: FinancialDataFilterBadge | null,
    public readonly supplier: FinancialDataFilterBadge | null,
    public readonly description: FinancialDataFilterBadge | null,
    public readonly currency: FinancialDataFilterBadge | null,
    public readonly amountOriginal: FinancialDataFilterBadge | null,
    public readonly amountConverted: FinancialDataFilterBadge | null,
    public readonly checkStatus: FinancialDataFilterBadge | null,
    public readonly expenseType: FinancialDataFilterBadge | null,
  ) {
  }
}

export class FinancialDataFilterBadge {
  constructor(
    public readonly label: string,
    public readonly options: FinancialDataFilterOption[] | string | null,
    public readonly labelValue: string | null = null,
  ) {
  }
}

export class FinancialDataFilterOption {
  constructor(
    public readonly id: number | string | null,
    public readonly name: string,
    public readonly relevantTo?: number | string | null,
  ) {
  }
}

export class RemoveFinancialDataFilterPayload {
  constructor(
    public readonly controlName: string,
    public readonly type: RemoveFinancialDataFilterType,
    public readonly option: FinancialDataFilterOption | null = null,
  ) {
  }
}

export enum RemoveFinancialDataFilterType {
  Grouped,
  Select,
  Single,
}

export class FinancialDataFilterDto {
  [key: string]: any;

  constructor(
    public readonly boatId: number,
    public readonly expense: ExpenseType | null,
    public readonly checkStatus: ExpenseStatus | null,
    public readonly amountOriginalFrom: number | null,
    public readonly amountOriginalTo: number | null,
    public readonly amountConvertedFrom: number | null,
    public readonly amountConvertedTo: number | null,
    public readonly dateFrom: string | null,
    public readonly dateTo: string | null,
    public readonly description: string | null,
    public readonly sortField: string | null,
    public readonly sortMode: SortDirection | null,
    public readonly page: number | null,
    public readonly limit: number | null,
    public readonly show: number | null = null,
    public readonly view: string | null = null,
    public readonly optionGuest: number | null,
    public readonly supplier: string = '',
    public search: string = '',
    public readonly charterId: number | null = null,
  ) {
  }
}

export class FinancialDataByChartDto {
  constructor(
    public readonly boatId: number,
    public readonly chartType: AppChartType,
    public readonly id: string | null,
    public readonly page: string | number,
    public readonly limit: string | number,
    public readonly sortField: string,
    public readonly sortMode: string,
    public readonly year?: string | null,
    public readonly month?: string | null,
    public readonly dateStart?: string | null,
    public readonly dateEnd?: string | null,
    public readonly currencyId?: string | null,
    public readonly charterId?: string | null,
  ) {
  }
}

export interface CharterFinancialDataParams {
  readonly boat: number;
  readonly department: string;
  readonly currency: string | null;
  readonly page: number | string;
  readonly limit: number | string | null;
  readonly sortField: string | null;
  readonly sortMode: SortDirection | null;
}

export class FinancialDataRouteParams {
  constructor(
    public readonly id: string | null,
    public chartType: AppChartType,
    public reportViewType: ReportViewType,
    public page: string | number,
    public limit: string | number,
    public sortBy: string,
    public sortDir: SortDirection,
    public year: string | null,
    public month: string | null,
    public dateFrom: string | null,
    public dateTo: string | null,
    public currencyId: string | null,
    public charterId: string | null,
  ) {
  }
}

export interface FinancialDataSortItem {
  readonly id: any;
  readonly name: string;
}
